<template>
  <div class="performance">
    <h5 v-if="!hideTitle" class="title">
      My {{ sectionName }} Performance Breakdown
    </h5>
    <div class="performanceChart-box">
      <div class="performanceChart" ref="performanceChart" />
    </div>
    <div class="table-box">
      <table
        class="table table-bordered"
        v-if="section === 'Reading' || section === 'Listening'"
      >
        <thead>
          <tr>
            <th class="green-td" rowspan="2">
              <div>
                {{ $t("pageTitle.Question Type") }}
              </div>
            </th>
            <th class="green-td" rowspan="2">
              <div>
                {{ $t("pageTitle.My Accuracy") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('pageTitle.Your performance on this test')"
                  placement="top"
                >
                  <span>
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" rowspan="2">
              <div>
                {{ $t("pageTitle.My Average") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('pageTitle.Your cumulative average')"
                  placement="top"
                >
                  <span>
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" rowspan="2">
              <div>
                {{ $t("pageTitle.Average") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    $t('pageTitle.Everyone’s average for this question type')
                  "
                  placement="top"
                >
                  <span>
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" :colspan="passagesNumber">Questions</th>
          </tr>
          <tr>
            <th
              class="green-td"
              width="150px"
              v-for="(i, index) in passagesNumber"
              :key="index"
            >
              <div style="min-width:100px">Passage{{ i }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tag, index) in tags" :key="index">
            <td class="text-left" style="width:200px">
              <b>
                {{ tag.name }}
              </b>
            </td>
            <td
              style="width:150px"
              v-html="getAccuracy(tag.correct_count, tag.question_count)"
            ></td>
            <td
              style="width:100px"
              v-html="getAvg(tag.user_correct_count, tag.user_question_count)"
            ></td>
            <td
              style="width:100px"
              v-html="getAvg(tag.total_correct_count, tag.total_question_count)"
            ></td>
            <td
              class="text-left"
              style="padding:5px"
              v-for="(i, index) in passagesNumber"
              :key="index"
            >
              <span
                v-for="(question, key) in tag.word_in_context[i]"
                :key="key"
                :class="
                  question.is_correct
                    ? 'view-question correct'
                    : 'view-question error'
                "
              >
                {{ question.order }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered" v-if="section === 'Speaking'">
        <thead>
          <tr>
            <th class="green-td" rowspan="2" width="250px">
              {{ $t("pageTitle.Question Type") }}
            </th>
            <th class="green-td" rowspan="2" width="150px">
              {{ $t("pageTitle.My Score") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('pageTitle.Your performance on this test')"
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2" width="150px">
              {{ $t("pageTitle.My Average Score") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('pageTitle.Your cumulative average')"
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2" width="150px">
              {{ $t("pageTitle.Average") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  $t('pageTitle.Everyone’s average for this question type')
                "
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2" width="180px">Question</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tag, index) in tags" :key="index">
            <td class="text-left" style="width:100px">
              <b>
                {{ tag.name }}
              </b>
            </td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.correct_count,
                  tag.question_count / speakingMaxScore
                )
              "
            ></td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.user_correct_count,
                  tag.user_question_count / speakingMaxScore
                )
              "
            ></td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.total_correct_count,
                  tag.total_question_count / speakingMaxScore
                )
              "
            ></td>
            <td class="text-left" style="padding:5px">
              <span
                v-for="(question, key) in tag.word_in_context"
                :key="key"
                class="task-question"
              >
                {{ key }}
              </span>
            </td>
          </tr>
          <tr v-for="(subScore, index) in subScores" :key="index">
            <td class="text-left" style="width:100px">
              <b>
                {{ subScore.title[lang] }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.scoreCount, subScore.totalScore) }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.myTakenCount, subScore.myTotalScore) }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.pastTotalTakenCount, subScore.pastTotalScore) }}
              </b>
            </td>
            <td class="text-left" style="padding:5px" />
          </tr>
        </tbody>
      </table>
      <table
        class="table table-bordered"
        v-if="
          section === 'Writing_integrated' || section === 'Writing_independent'
        "
      >
        <thead>
          <tr>
            <th class="green-td" rowspan="2" style="width:250px">
              {{ $t("pageTitle.Question Type") }}
            </th>
            <th class="green-td" rowspan="2" style="width:150px">
              {{ $t("pageTitle.My Score") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('pageTitle.Your performance on this test')"
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2" style="width:150px">
              {{ $t("pageTitle.My Average Score") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('pageTitle.Your cumulative average')"
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2" style="width:150px">
              {{ $t("pageTitle.Average") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  $t('pageTitle.Everyone’s average for this question type')
                "
                placement="top"
              >
                <span>
                  <i class="fa fa-question-circle question-icon"></i>
                </span>
              </el-tooltip>
            </th>
            <th class="green-td" rowspan="2">Task</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tag, index) in filterWritingSection(tags)" :key="index">
            <td class="text-left" style="width:100px">
              <b>
                {{ tag.name }}
              </b>
            </td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.correct_count,
                  tag.question_count / writingMaxScore
                )
              "
            ></td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.user_correct_count,
                  tag.user_question_count / writingMaxScore
                )
              "
            ></td>
            <td
              style="width:200px"
              v-html="
                getAverageScore(
                  tag.total_correct_count,
                  tag.total_question_count / writingMaxScore
                )
              "
            ></td>
            <td class="text-left" style="padding:5px">
              <span
                v-for="(question, key) in tag.word_in_context"
                :key="key"
                class="task-question"
              >
                {{ key }}
              </span>
            </td>
          </tr>
          <tr v-for="(subScore, index) in subScores" :key="index">
            <td class="text-left" style="width:100px">
              <b>
                {{ subScore.title[lang] }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.scoreCount, subScore.totalScore) }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.myTakenCount, subScore.myTotalScore) }}
              </b>
            </td>
            <td style="width:200px">
              <b class="text-success">
                {{ getSubScoreAverage(subScore.pastTotalTakenCount, subScore.pastTotalScore) }}
              </b>
            </td>
            <td class="text-left" style="padding:5px" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import echarts from "echarts";

export default {
  components: {},

  mixins: [],

  props: ["hideTitle", "section", "tags", "subScores", "passagesNumber"],
  data() {
    return {
      charts: null
    };
  },
  computed: {
    Decimal() {
      return Decimal;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    speakingMaxScore() {
      return 4;
    },
    writingMaxScore() {
      return 5;
    },
    sectionName() {
      if (
        ["Writing_independent", "Writing_integrated"].includes(this.section)
      ) {
        return "Writing";
      }
      return this.section;
    }
  },
  watch: {
    subScores() {
      if (this.subScores.length > 0) {
        this.draw();
      }
    }
  },

  mounted() {
    this.draw();
  },

  methods: {
    filterWritingSection(tags) {
      let SECTION_TAG_ID = null;
      switch (this.section) {
        case "Writing_integrated":
          SECTION_TAG_ID = 78;
          break;
        case "Writing_independent":
          SECTION_TAG_ID = 77;
          break;
        default:
          return {};
      }
      const targetTagKey = Object.keys(tags).find(
        key => Number(key) === SECTION_TAG_ID
      );
      if (targetTagKey === undefined) return {};
      return { [targetTagKey]: tags[targetTagKey] };
    },
    draw() {
      this.charts = echarts.init(this.$refs["performanceChart"]);
      let chartData = [];
      let indicator = [];
      let max = 0;
      switch (this.section) {
        case "Reading":
          max = 100;
          break;
        case "Listening":
          max = 100;
          break;
        case "Speaking":
          max = this.speakingMaxScore;
          break;
        case "Writing_independent":
        case "Writing_integrated":
          max = this.writingMaxScore;
          break;
        default:
      }
      let myAccuracy = [];
      let average = [];
      let myAverage = [];

      if (["Reading", "Listening"].includes(this.section)) {
        for (let key in this.tags) {
          let tag = this.tags[key];
          indicator.push({
            name: tag.name,
            max
          });
          myAccuracy.push(this.getPer(tag.correct_count, tag.question_count));
          average.push(
            this.getPer(tag.total_correct_count, tag.total_question_count)
          );
          myAverage.push(
            this.getPer(tag.user_correct_count, tag.user_question_count)
          );
        }
      }

      if (
        ["Speaking", "Writing_integrated", "Writing_independent"].includes(
          this.section
        )
      ) {
        let tags = { ...this.tags };
        if (["Writing_integrated", "Writing_independent"].includes(this.section)) {
          tags = this.filterWritingSection(tags);
        }
        for (let key in tags) {
          let tag = this.tags[key];
          indicator.push({
            name: tag.name,
            max
          });
          myAccuracy.push(
            Number(
              Decimal.div(
                tag.correct_count,
                Decimal.div(tag.question_count, this.speakingMaxScore)
              )
            )
          );
          average.push(
            Number(
              Decimal.div(
                tag.total_correct_count,
                Decimal.div(tag.total_question_count, this.speakingMaxScore)
              )
            )
          );
          myAverage.push(
            Number(
              Decimal.div(
                tag.user_correct_count,
                Decimal.div(tag.user_question_count, this.speakingMaxScore)
              )
            )
          );
        }

        for (let key in this.subScores) {
          const MAX_SUB_SCORES = 5;
          let subScore = this.subScores[key];
          indicator.push({
            name: subScore.title[this.lang],
            max: MAX_SUB_SCORES
          });
          myAccuracy.push(
            Number(Decimal.div(subScore.totalScore, subScore.scoreCount))
          );
          average.push(
            Number(
              Decimal.div(subScore.pastTotalScore, subScore.pastTotalTakenCount)
            )
          );
          myAverage.push(
            Number(Decimal.div(subScore.myTotalScore, subScore.myTakenCount))
          );
        }
      }
      chartData.push({
        name: this.$t("pageTitle.My Accuracy"),
        value: myAccuracy
      });
      chartData.push({
        name: this.$t("pageTitle.My Average"),
        value: myAverage
      });
      chartData.push({
        name: this.$t("pageTitle.Average"),
        value: average
      });
      const option = {
        color: ["#ff8920", "#409EFF", "#212529"],
        legend: {
          data: [
            this.$t("pageTitle.My Accuracy"),
            this.$t("pageTitle.My Average"),
            this.$t("pageTitle.Average")
          ]
        },
        radar: {
          // shape: 'circle',
          indicator: indicator,
          splitArea: {
            show: true,
            areaStyle: {
              color: ["#fff"] // 图表背景网格的颜色
            }
          },
          nameGap: 10,
          name: {
            rich: {
              a: {
                color: "white",
                align: "center",
                backgroundColor: "#42a16a",
                padding: 5,
                borderRadius: 4,
                fontWeight: 700
              }
            },
            formatter: a => {
              return `{a|${a}}`;
            }
          }
        },
        series: [
          {
            type: "radar",
            data: chartData
          }
        ]
      };
      this.charts.setOption(option);
    },
    getPer(correctQuestionsCount, questionsCount) {
      let correctCount = correctQuestionsCount;
      let allCount = questionsCount;
      let percent = Math.round((correctCount / allCount) * 100);
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        switch (this.section) {
          case "Reading":
            return percent;
          case "Listening":
            return percent;
          default:
        }
      } else {
        return 0;
      }
    },
    getSubScoreAverage(count, score) {
      const convertScoreToLevel = score => {
        if (score === 5) return "A+";
        const scoreMapLevels = [
          { level: "E", min: 1, max: 1.2 },
          { level: "E+", min: 1.2, max: 1.5 },
          { level: "D-", min: 1.5, max: 1.8 },
          { level: "D", min: 1.8, max: 2.2 },
          { level: "D+", min: 2.2, max: 2.5 },
          { level: "C-", min: 2.5, max: 2.8 },
          { level: "C", min: 2.8, max: 3.2 },
          { level: "C+", min: 3.2, max: 3.5 },
          { level: "B-", min: 3.5, max: 3.8 },
          { level: "B", min: 3.8, max: 4.2 },
          { level: "B+", min: 4.2, max: 4.5 },
          { level: "A-", min: 4.5, max: 4.8 },
          { level: "A+", min: 4.8, max: 5 }
        ];
        const targetLevel = scoreMapLevels.find(
          ({ min, max }) => min <= score && score < max
        );
        if (targetLevel) {
          return targetLevel.level;
        } else {
          return "";
        }
      };
      if (count === 0) return "-";
      return convertScoreToLevel(Number(Decimal.div(score, count)));
    },
    getAverageScore(exam_count, question_count) {
      let maxScore = null;
      switch (this.section) {
        case "Speaking":
          maxScore = this.speakingMaxScore;
          break;
        case "Writing_independent":
        case "Writing_integrated":
          maxScore = this.writingMaxScore;
          break;
        default:
      }
      if (exam_count > 0 && question_count > 0) {
        // chapter 的正確數量 / (這張考券的題目數量 / 5)
        let score = (exam_count / question_count).toFixed(2);
        return `<b class="text-success">${Number(score)}/${maxScore} </b> `;
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAccuracy(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      const displayAccuracy = `${correctQuestionsCount}/${questionsCount}`;
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${displayAccuracy} (${percent}%)</b> `;
        } else {
          return `<b class="text-success">${displayAccuracy} (${percent}%)</b> `;
        }
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAvg(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      if (correctQuestionsCount >= 0 && questionsCount > 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${percent}%</b> `;
        } else {
          return `<b class="text-success">${percent}%</b> `;
        }
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    }
  }
};
</script>

<style scoped>
.table-box {
  width: 100%;
  overflow: auto;
}

.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.performance {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.table {
  background-color: white;
  margin: 0;
  border-radius: 5px;
}
::v-deep .view-question {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
}
::v-deep .task-question {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
  background-color: var(--themeColor);
  color: white;
}
::v-deep .correct {
  border: 1px solid var(--themeColor);
}
::v-deep .error {
  border: 1px solid red;
}
.performanceChart-box {
  width: 100%;
}
.performanceChart {
  width: 100%;
  height: 500px;
}
@media screen and (max-width: 1200px) {
  .performance {
    padding: 10px;
  }
}
</style>
