<template>
  <div>
    <h4>
      Speaking:
      <span>
        <b style="color: #ff8920;"> {{ score }}</b>
      </span>
    </h4>
    <div class="answer-list">
      <table
        class="table table-bordered"
        v-for="(item, index) in list"
        :key="item.exam_question_id"
      >
        <thead>
          <tr>
            <th class="green-td view-question-tr">
              <a
                class="view_question"
                :href="getResolve(item.toefl_question_id)"
              >
                <b>Question {{ index + 1 }}</b> <i class="far fa-eye"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="row audio-row">
              <div class="col-md-12">
                <div
                  v-if="item.user_answer && item.time > 0"
                  style="display: flex"
                >
                  <audio
                    style="height:40px;margin-right:20px;flex:1"
                    :id="item.exam_question_id"
                    :src="item.user_answer[0]"
                    controls
                    controlsList="nodownload"
                  ></audio>
                  <el-dropdown
                    style="width:120px;line-height:40px"
                    size="small"
                    @command="changeSpeed"
                    :key="index"
                  >
                    <span class="audio-speed">
                      <b class="text-success">Speed: {{ item.speed }} X</b>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(it, index) in multipleArray"
                        :key="index"
                        :command="composeValue(it, item)"
                      >
                        {{ it }} X
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div v-else class="text-danger">
                  N/A
                </div>
              </div>
              <div
                class="col-sm-12"
                v-if="examInfo.can_score === 1 || examInfo.can_suggest === 1 || teacherScored"
              >
                <el-divider content-position="left">Grades and Comments</el-divider>
              </div>
              <div
                class="col-sm-12 text-left d-flex align-items-center"
                v-if="examInfo.can_score === 1 || teacherScored"
              >
                <b class="score-title">Response Score:&nbsp;</b>
                <GradeLevelSelector
                  section="speaking"
                  :value="item.score"
                  @change="score => setAndAutoSaveScore(index, score)"
                />
                <el-button
                  type="warning"
                  size="mini"
                  style="margin-left:20px"
                  @click="setQuestion(item)"
                  v-show="!teacherScored"
                >
                  錄音有問題？
                </el-button>
              </div>
              <SubScoreTable
                v-if="
                  (examInfo.can_score === 1 || teacherScored) && commentCategories[TEST_CATEGORY]
                "
                :value="(item.answer_score_detail || {}).detail_score_comments"
                :testCategory="TEST_CATEGORY"
                :testCategories="commentCategories[TEST_CATEGORY]"
                :hideSubComment="examInfo.can_suggest? !examInfo.can_suggest : teacherScored ? !teacherScored: examInfo.can_suggest"
                @change="subScores => setSubScores(index, subScores)"
              />
              <div class="col-sm-12 text-left">
                <div v-if="examInfo.can_suggest === 1 || teacherScored">
                  <div class="overall-comments score-title">
                    <b>Overall Comments:</b>
                  </div>
                  <el-input
                    type="textarea"
                    :rows="8"
                    placeholder="請輸入內容"
                    v-model="item.suggest"
                    @change="leaveAlert"
                  >
                  </el-input>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog title="錄音問題反饋" :visible.sync="showQuestion" width="70%">
      <el-form ref="form" :model="feedback">
        <el-form-item label="當前題目" class="text-left">
          <b>Speaking</b>
        </el-form-item>
        <el-form-item label="具體問題">
          <el-input
            type="textarea"
            v-model="description"
            :rows="7"
            :placeholder="$t('feedback.Details')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="showQuestion = false">
            取 消
          </el-button>
          <el-button type="primary" @click="postFeedback">
            發送問題原因並退換T幣給學生
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import GradeLevelSelector from "@/views/toefl/grading/sections/GradeLevelSelector";
import SubScoreTable from "@/views/toefl/grading/sections/SubScoreTable";
import TOEFL from "@/apis/toefl";

export default {
  components: {
    GradeLevelSelector,
    SubScoreTable
  },

  mixins: [],

  props: ["list", "score", "examInfo", "commentCategories"],
  data() {
    return {
      feedback: {},
      score_list: [1, 2, 3, 4],
      multipleArray: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
      notes: false,
      textarea: "",
      showQuestion: false,
      description: ""
    };
  },
  computed: {
    TEST_CATEGORY() {
      return "speaking_all";
    },
    ...mapState("user", ["profile"]),
    teacherScored() {
      return this.examInfo.can_score === 0;
    }
  },

  watch: {
    list() {
      this.getAudio();
    }
  },

  async mounted() {
    await this.getProfile();
    this.getAudio();
  },

  methods: {
    leaveAlert() {
      this.$nextTick(() => {
        window.addEventListener("beforeunload", event => {
          event.preventDefault();
          event.returnValue = "";
        });
      });
    },
    setQuestion() {
      this.showQuestion = true;
    },
    async postFeedback() {
      await TOEFL.postFeedback(this.$route.query.id, {
        description: this.description
      });
      this.showQuestion = false;
      this.$message({
        message: "處理成功！Thanks♪(･ω･)ﾉ",
        type: "success"
      });
      this.$router.go(0);
    },
    addNotes() {
      this.notes = true;
    },
    getAudio() {
      let speaking_list = this.list;
      speaking_list.forEach(element => {
        let audio = document.getElementById(element.exam_question_id);
        audio.load();
        element.time = 0;
        audio.oncanplay = function() {
          element.progress = audio.currentTime;
          element.time = audio.duration;
          element.speed = 1;
        };
        this.$set(element, "progress", 0);
      });
    },
    composeValue(it, item) {
      return {
        it: it,
        item: item
      };
    },
    changeSpeed(command) {
      let audio = document.getElementById(command.item.exam_question_id);
      audio.playbackRate = command.it;
      command.item.speed = command.it;
    },
    controlAudio(item) {
      let audio = document.getElementById(item.exam_question_id);
      audio.addEventListener(
        "timeupdate",
        function() {
          //更新进度条
          item.progress = audio.currentTime;
        },
        false
      );
      audio.addEventListener(
        "ended",
        function() {
          //播放完成
          item.progress = 0;
          item.canPlay = true;
          audio.playbackRate = 1;
        },
        false
      );
      if (item.canPlay) {
        audio.play();
        item.canPlay = false;
      } else {
        audio.pause();
        item.canPlay = true;
      }
    },
    getTime(seconds_remaining) {
      var minute;
      var second;
      minute = Math.floor(seconds_remaining / 60);
      second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    },
    setAndAutoSaveScore(index, score) {
      this.$emit("setTestScore", {
        index,
        payload: {
          score,
          answer_score_detail: {
            ...this.list[index].answer_score_detail,
            comment_type: this.TEST_CATEGORY
          }
        }
      });
      this.$emit("setScore", { index, score });
    },
    setSubScores(index, subScores) {
      this.$emit("setTestScore", {
        index,
        payload: {
          answer_score_detail: {
            ...this.list[index].answer_score_detail,
            comment_type: this.TEST_CATEGORY,
            detail_score_comments: subScores
          }
        }
      });
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.green-td {
  position: relative;
}

.view_question {
  color: #fff !important;
  text-decoration: none;
}

.view-question-tr:hover {
  opacity: 0.7;
}

.view_question:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.audio-row {
  margin: 0;
  padding: 32px;
}

.score-title {
  font-size: 20px;
}

.overall-comments {
  margin-top: 4px;
  margin-bottom: 20px;
}

div a {
  cursor: pointer;
}
.Audio {
  position: relative;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
}
.pgs {
  background-color: rgb(227, 232, 238);
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 40px;
  border-radius: 20px;
}
.pgs-play {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--themeColor);
  z-index: 1;
}
.audio-name {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  text-align: center;
  color: #666;
  font-size: 12px;
}
.controls {
  position: absolute;
  width: 100%;
  height: 40px;
  padding: 0;
  text-align: center;
}
.play-pause {
  border: 0;
  outline: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  background: none;
  display: inline-block;
  color: white;
}
.icon-play {
  width: 40px;
  height: 40px;
  display: block;
  color: #478f59;
}
.icon-pause {
  width: 40px;
  height: 40px;
  display: block;
  color: #478f59;
}
.controls span {
  color: #fff;
  display: inline-block;
  line-height: 40px;
  font-size: 18px;
}
.audio-time,
.played-time {
  display: inline-block;
}
.Audio .icon-btn {
  font-size: 30px;
}
.answer-list {
  margin-top: 20px;
}
</style>
